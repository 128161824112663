<script setup>
import Software from './software'
import Search from './search'
import { useCookies } from "@vueuse/integrations/useCookies";
import { useCartStore, useAuthStore } from "@/stores";
const authStore = useAuthStore();
const cookies = useCookies(['sails.sid']);
const sid = cookies.get('sails.sid');
let lists = ref([])
let data = ref([])
let show = ref(false)
let show2 = ref(false)
const isBubbleVisible = ref(false);

const getNavigation = async () => {
    await nextTick();
    const res = await useFetch(constants.BASE_URL + api.getNavigation,
        { method: 'GET' })
    console.log(res, 'list1232212');
    lists.value = res.data.value.data

    // lists.value.push(...res?.data?._rawValue?.data)
    console.log(lists.value, 'list123223');
}
getNavigation()

const openTanchan = (index) => {
    console.log(index, 'index');
    data.value = lists.value[index].children
    show.value = true
    show2.value = false
    isBubbleVisible.value =false
    console.log(data.value, 'data.value');
}

const openSearch = () => {
    console.log('openSearch');
    show2.value = true
    show.value = false
    isBubbleVisible.value =false
}

const closeSoftware = () => {
    console.log('close');
    show.value = false
    show2.value = false
    isBubbleVisible.value =false
}

const showBubble = () => {
    console.log('showBubble');
    show.value = false
    show2.value = false
    isBubbleVisible.value = true;
};

const toLogin2 = async () => {
    await nextTick();
  await useFetch(constants.BASE_URL + api.logout + '?sid=' + sid ,{ method: 'POST' })
  cookies.remove("sails.sid");
  authStore.delUserInfo()
  navigateTo("/login");
};
const toAccount = () => {
    if(!sid){
      navigateTo("/login");
      return
    }
    navigateTo("/my?module=person");
};
const toOrders = () => {
    if(!sid){
      navigateTo("/login");
      return
    }
  navigateTo("/my?module=orders");
};

const toCart = () => {
    if(!sid){
      navigateTo("/login");
      return
    }
    navigateTo("/cart");
}
const closeBubble = () => {
    console.log('closeBubble');
    isBubbleVisible.value = false;
};

const goHome = () => {
    navigateTo("/");
    closeSoftware()
}


</script>

<template>
    <div class="pack"  @mouseleave="closeBubble">
        <div class="headerBox2" >
            <div class="loge">
                <img src="../../../assets/imgs/header/store.png" alt="" @click="goHome" />
                <div class="text" v-for="(item, index) in lists" @mouseenter='openTanchan(index)'>{{ item.name }}</div>
            </div>
            <div class="head-lift">
                <img src="../../../assets/imgs/header/search.png" alt="" @mouseenter="openSearch" />
                <img class="cart" v-if="sid" src="../../../assets/imgs/header/cart.png" alt="" @click="toCart"/>
                <img class="my" src="../../../assets/imgs/header/my.png" alt="" @mouseenter="showBubble"/>
                <div class="bubble" v-if="isBubbleVisible"   @mouseleave="closeSoftware">
                    <span @click="toAccount" @mouseover="showBubble">My Account</span>
                    <span @click="toOrders" @mouseover="showBubble">My Orders</span>
                    <span class="divider" @mouseover="showBubble"></span>
                    <span @click="toLogin2" @mouseover="showBubble">{{ sid ? 'Sign out' :'Sign in' }}</span>
                </div>
            </div>
        </div>
    </div>
    <Software v-if="show" :data="data" @close="closeSoftware"/>
    <Search v-if="show2" @close="closeSoftware" />
</template>

<style lang="less" scoped>
.pack {
    width: 100%;
    height: 50px;
    background: #000000;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1002;

    .headerBox2 {
        transition: all 0.5s ease-in-out;
        background: #000000;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1002;
        height: 50px;
        display: flex;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .loge {
            display: flex;
            align-items: end;
            width: 260px;

            img {
                width: 83px;
                height: 28px;
                margin-right: 41px;
                cursor: pointer;
            }

            .text {
                color: #fff;
                font-size: 15px;
                font-weight: 500;
                line-height: 18px;
                margin-right: 30px;
                cursor: pointer;
            }
        }

        .head-lift {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 100px;
            width: 108px;
            font-size: 14px;
            color: #fff;
            font-weight: 500;
            line-height: 20px;
            position: relative;
            .target-element {
                padding: 10px;
                background-color: #4CAF50;
                color: white;
                cursor: pointer;
            }

            .bubble {
                position: absolute;
                top: 30px;
                right: 0;
                z-index: 1002;
                background-color: #181818;
                color: white;
                padding: 10px;
                border-radius: 5px;
                height: 185px;
                width: 147px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                span {
                    cursor: pointer;
                }
                .divider {
                    width: 100%;
                    height: 1px;
                    background-color: #222222;
                }
            }

            img {
                width: 16px;
                height: 16px;
                margin-right: 30px;
                cursor: pointer;
            }

           .cart {
                width: 20px;
                height: 20px;
            }

         .my {
            width: 20px;
            height: 20px;
            }
        }
    }
}
</style>
