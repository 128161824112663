<script setup></script>

<template>
  <div class="page"> 
    <div class="headerBox">
    <div class="loge" @click="goHome">
      <img src="../../assets/imgs/gs-log.png" alt="">
      <span>GreenValley International</span>
    </div>
    <div class="head-lift">
      <div class="contact" @click="goContact">Contact Us</div>
      <div class="head-US">
        <img src="../../assets/imgs/header/world.png" alt="">
        <span>United States（English）</span>
      </div>
    </div>
  </div>
  </div>

  <toolbar />

</template>

<script setup>
import toolbar from "./components/toolbar.vue";

const goHome = () => {
  window.location.href ='https://www.greenvalleyintl.com/'
}

const goContact = () => {
  window.location.href ='https://www.greenvalleyintl.com/contactNew'
}
</script>

<style lang="less" scoped>
.page {
  background: #181818;
  width: 100%;
}
.headerBox {
  z-index: 10;
  transition: all 0.5s ease-in-out;
  background: #181818;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1001;
  height: 50px;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .loge {
    display: flex;
    align-items: center;  
    cursor: pointer;
    img {
      width: 98px;
      height: 33px;
    }
    span {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      line-height: 33px;
    }
  }
  .head-lift {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 100px;
    width: 300px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    line-height: 20px;
    .contact {
      cursor: pointer;
    }
    .head-US {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
      span {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
</style>
