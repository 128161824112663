<script setup>
const props = defineProps(["data"]);
const emit = defineEmits(["close"]);
const inpValue = ref("");
const show = ref(false)
const close = () => {
    console.log('close');
    emit("close");
}
const Recommendations = ref([])
const searchList = ref([])

const getNavigation = async () => {
    const res = await useFetch(constants.BASE_URL + api.getHotRecommend,
        { method: 'GET' })
    console.log(res, 'list1232212');
    Recommendations.value = res.data.value.data
    searchList.value = res.data.value.data

    // lists.value.push(...res?.data?._rawValue?.data)
    console.log(Recommendations.value, 'Recommendations2312');
}
getNavigation()

const showBlur = () => {
    console.log('showBlur');
    show.value = false
}

const showFocus = () => {
    console.log('showBlur');
    show.value = true
}

const handleEnter = async () => {
    console.log('handleEnter');
    const res = await useFetch(constants.BASE_URL + api.search + `?name=${inpValue.value}`,
        { method: 'GET' })
    console.log(res, 'list1232212');
    searchList.value = res.data.value.data
    console.log(searchList.value, 'Recommendations2312');
}
const goBanner = (item) => {
  console.log(item, 'item============');
  if (item?.clickUrl) {
    window.location.href = item.clickUrl
  } else {
    navigateTo('/product/'+ item.goodsCode)
  }
}
</script>

<template>
    <div class="bg" @click="close">
        <div class="pack2" @click.stop="showBlur" @mouseleave="close">
            <div class="box">
                <div class="Search" @click.stop="">
                <img src="../../../assets/imgs/index/search.png" alt="">
                <input @keyup.enter="handleEnter" @input="handleEnter" @focus.stop="showFocus" class="inp"
                    v-model="inpValue" placeholder="Search Products…"></input>
                <div class="searchBox" v-if="show">
                    <div class="searchBox-item" v-for="item in searchList" @click="goBanner(item)">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="list">
                <div class="title">Recommendations</div>
                <div class="list-item" v-for="item in Recommendations" @click="goBanner(item)">
                    {{ item.name }}
                </div>
            </div>
            </div>

        </div>
    </div>


</template>

<style lang="less" scoped>
.bg {
    width: 100vw;
    height: 100vh;
    background-color: #4c4c4c7c;
    position: sticky;
    top: 50px;
    z-index: 1002;

    input {
        outline: none;
    }

    .pack2 {
        width: 100%;
        height: 500px;
        background: #000000;
        color: #fff;
        display: flex;
        padding: 50px 0px;
        flex-direction: column;
        .box {
            width: 1200px;
            margin: 0 auto;
            height: 100%;
            .Search {
            display: flex;
            align-items: center;
            width: 500px;
            height: 50px;
            border-radius: 25px;
            padding: 0 20px;
            position: relative;

            .searchBox {
                position: absolute;
                top: 55px;
                left: 50px;
                width: 400px;
                min-height: 200px;
                max-height: 400px;
                background: #2b2b2b;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                color: #888888;
                font-size: 24px;

                .searchBox-item {
                    font-size: 16px;
                    color: #888888;
                    margin-bottom: 18px;
                    cursor: pointer;
                }
            }

            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            input::-webkit-input-placeholder {
                /* Chrome, Opera, Safari */
                color: #888888;
                font-size: 24px;
            }

            input:-moz-placeholder {
                /* Firefox 18- */
                color: #888888;
                font-size: 24px;
                opacity: 1;
                /* 修复老版本Firefox的不透明度问题 */
            }

            input::-moz-placeholder {
                /* Firefox 19+ */
                color: #888888;
                font-size: 24px;
                opacity: 1;
                /* 修复Firefox的不透明度问题 */
            }

            input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #888888;
                font-size: 24px;
            }

            .inp {
                width: 400px;
                height: 50px;
                border: none !important;
                font-size: 24px;
                background: #000000;
                color: #fff;
            }

        }

        .list {
            width: 300px;
            height: 400px;
            margin-top: 50px;
            display: flex;
            flex-direction: column;

            .title {
                font-size: 14px;
                font-weight: 600;
                color: #888888;
                margin-bottom: 16px;
            }

            .list-item {
                font-size: 16px;
                color: #fff;
                margin-bottom: 18px;
                cursor: pointer;
            }

        }
        }

    }
}
</style>
