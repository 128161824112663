<script setup>
const props = defineProps(["data"]);
const emit = defineEmits(["close"]);
const close = () => {
  console.log('close');
  emit("close");
}

const goProduct = (item) => {
    console.log('goProduct',item);
    navigateTo('/product/'+ item.goodsCode);
}

</script>

<template>
    <div class="bg" @click="close">
        <div class="pack2" @mouseleave="close">
            <div class="box">
                <div class="headerBox2" v-for="item in props.data" >
            <div class="title">{{ item.name }}</div>
            <div v-if="!item.children" class="context">
                <img class="img" :src="item?.file?.fileUrl" alt="Carousel Image" />
                <div class="des">{{ item.name }}</div>
            </div>
            <div v-else v-for="item2 in item.children" class="context" @click.stop="goProduct(item2)">
                <img class="img" :src="item2?.file?.fileUrl" alt="Carousel Image" />
                <div class="des">{{ item2.name }}</div>
            </div>
        </div>
            </div>

    </div>
    </div>


</template>

<style lang="less" scoped>
.bg {
    width: 100vw;
    height: 100vh;
    background-color: #4c4c4c7c;
    position: sticky;
    top: 50px;
    z-index: 1002;
    overflow: hidden;
    .pack2 {
    width: 100%;
    height: 500px;
    background: #000000;
    color: #fff;
    display: flex;
    padding: 50px 0px;
    .box {
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        .headerBox2 {
        color: #fff;
        display: flex;
        flex-direction: column;
        align-self: start;
        width: 400px;
        height: 400px;
        background: #000000;
        float: left;

        .title {
            font-size: 14px;
            margin-bottom: 20px;
        }

        .des {
                font-size: 26px;
                font-weight: 600;
        }

        .context {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            cursor: pointer;
            .des {
                font-size: 26px;
                font-weight: 600;
            }

            .img {
                width: 50px;
                height: 50px;
                margin: 0 20px;
                border-radius: 10px;

            }
        }

    }
    }

}
}

</style>
